import { computed } from 'vue'

import { priceDisplayTheResults } from '../utils/priceDisplayTheResults'

export const usePrices = ({ goodsInfo, sizeInfo = {}, config, language }) => {
  // 售价
  const salePrice = computed(() => {
    const goodsInfoForSize = sizeInfo.value?.goodsInfoForSize
    return goodsInfoForSize?.salePrice || goodsInfo.value.salePrice || {}
  })

  // 老划线价
  const retailPrice = computed(() => {
    const goodsInfoForSize = sizeInfo.value?.goodsInfoForSize
    return goodsInfoForSize?.retailPrice || goodsInfo.value.retailPrice || {}
  })

  // 是否ab价
  const isABPrice = computed(() => {
    return goodsInfo.value?.promotionInfo?.length && goodsInfo.value?.promotionInfo?.some(item => +item?.typeId === 32)
  })

  // 付费会员的一些信息
  const { price } = goodsInfo.value?.sheinClubPromotionInfo || {}
  const { amountWithSymbol: sheinClubPriceWithSymbol } = price || {}
  const sheinClubPriceWithSymbolVal = config.showSheinClubPriceOnSale && sheinClubPriceWithSymbol // 付费会员占用售价坑位的价格

  // TODO: 是否需要
  const estimatedPriceInfo = computed(() => {
    return goodsInfo.value.estimatedPriceInfo || {}
  })

  // 到手价相关信息
  const estimatedInfo = computed(() => {
    const { satisfied, estimatedPrice, estimatedDiscount, estimatedDiscountPrice, estimatedPriceType } = estimatedPriceInfo.value || {}

    const estimatedPriceWithSymbol = estimatedPrice?.amountWithSymbol
    const showEstimatedPriceOnSale = config.showEstimatedPriceOnSale
    const showNewStyleEstimated = config.showNewStyleEstimated
    
    const showEstimatedPrice = showEstimatedPriceOnSale && satisfied === 1 && estimatedPriceWithSymbol 

    const showBottomTagText = (showEstimatedPrice || isABPrice.value) && !config.noNeedAnyEstimatedPriceTag

    const isSheinClubEstimatedPrice = estimatedPriceInfo.value?.estimatedPriceType === 2 // 是付费会员到手价
    let labelText = ''
    if(showBottomTagText) {
      if(showNewStyleEstimated && (isABPrice.value || showEstimatedPrice)) {
        labelText = language.SHEIN_KEY_PC_31839 || 'with Coupon'
      } else {
        labelText = language.SHEIN_KEY_PC_27318 || 'Estimated'
      }
    }
    return {
      isSheinClubEstimatedPrice,
      // labelText: showBottomTagText ? (language.SHEIN_KEY_PC_27318 || 'Estimated') : '',
      labelText,
      priceInfo: showEstimatedPrice ? estimatedPrice : {},
      estimatedDiscount,
      estimatedDiscountPrice,
      estimatedPriceType, // 透出当前的到手价类型数据信息。。。。。
      showEstimatedPrice, // 是否有到手价(不包含AB价)
    }
  })

  const suggestedSalePriceType = goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo?.type
  // 德国special_de合规价逻辑处理 
  let specialData = {}
  if (suggestedSalePriceType) {
    specialData = priceDisplayTheResults(goodsInfo.value, language, { isPaid: !!( sheinClubPriceWithSymbolVal) }) || {}
  }

  return {
    specialData,
    salePrice,
    retailPrice,
    estimatedPriceInfo,
    estimatedInfo,
    sheinClubPriceWithSymbolVal
  }
}
